// Import necessary dependencies
import { React, useState } from 'react';
import {
  Box,
  Card,
  Button,
  CardContent,
  Typography,
  Grid,
  Modal,
} from '@mui/material';
import { styled } from '@mui/system';
import textContent from '../public/en.json';
import JP7Graphic from '../public/poster.jpg';
import Rulebook from '../public/Rulebook.pdf';

// Define the styled component
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#181A1B',
  margin: 'auto',
  width: '80%',
  [theme.breakpoints.down('md')]: {
    width: '100%', // Change width to 96% on small screens
  },
  border: 'none',
  boxShadow: 'none',
  fontFamily: 'Trebuchet MS !important',
}));

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Arial',
  // fontSize: '0.5rem',
  textAlign: 'justify',
  width: '96%',
  maxWidth: '1200px',
  margin: 'auto',
  fontWeight: 'thin',
  paddingBottom: '8px',
  color: '#EEEEEE',
}));

const StyledTypographyList = styled(Typography)(() => ({
  fontFamily: 'Arial',
  // fontSize: '0.5rem',
  textAlign: 'justify',
  width: '96%',
  maxWidth: '1200px',
  margin: 'auto',
  fontWeight: 'thin',
  paddingBottom: '8px',
  color: '#EEEEEE',
  fontSize: '0.8rem',
}));

// Define the component
export const CampInfoCard = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const PdfModal = ({ open, handleClose }) => {
    const handleBackdropClick = (event) => {
      if (event.target === event.currentTarget) {
        handleClose(); // Close the modal only if the backdrop is clicked directly
      }
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true, onClick: handleBackdropClick }}
      >
        <div>
          <div className="modalContent" style={{ zIndex: 10000 }}>
            <iframe
              src={`${Rulebook}`}
              style={{
                position: 'absolute',
                width: '80%',
                height: '80%',
                marginTop: '10vh',
                marginLeft: '10vw',
                zIndex: 9999,
                border: 0,
              }}
              title="waiverForm"
            ></iframe>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 0,
              }}
              className="customBackdrop"
              onClick={handleClose}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <StyledTypography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontWeight: 'medium',
          }}
        >
          {textContent.learnFrom}
        </StyledTypography>
        {textContent.aboutCamp.map((item) => (
          <StyledTypography variant="body2">{item}</StyledTypography>
        ))}

        <div style={{ width: '100%', marginTop: '2%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={JP7Graphic}
                width="100%"
                maxWidth="1200px"
                textAlign="center"
                margin="2% auto"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTypography
                variant="h6"
                sx={{ textDecoration: 'underline' }}
              >
                {textContent.campDetails}
              </StyledTypography>
              {textContent.campDetailList.map((item) => (
                <StyledTypographyList variant="body2">
                  • {item}
                </StyledTypographyList>
              ))}

              <StyledTypography
                variant="h6"
                sx={{ textDecoration: 'underline', textAlign: 'left' }}
              >
                {textContent.campersWillReceive}
              </StyledTypography>
              {textContent.campersReceiveList.map((item) => (
                <StyledTypographyList variant="body2">
                  • {item}
                </StyledTypographyList>
              ))}
              <StyledTypography
                variant="h6"
                sx={{ textDecoration: 'underline', textAlign: 'left' }}
              >
                {textContent.positionStations}
              </StyledTypography>

              <StyledTypographyList variant="body2">
                {textContent.positions}
              </StyledTypographyList>

              <StyledTypographyList
                variant="caption"
                sx={{ fontSize: '0.7rem', margin: '12px' }}
              >
                {textContent.refund}
              </StyledTypographyList>
              <StyledTypographyList variant="body2" sx={{ fontSize: '0.7rem' }}>
                {textContent.refundPolicy}
              </StyledTypographyList>
              <Button>
                <StyledTypographyList
                  variant="body2"
                  sx={{ fontSize: '0.7rem' }}
                  onClick={handleModalOpen}
                >
                  Rules
                </StyledTypographyList>
                <PdfModal open={modalOpen} handleClose={handleModalClose} />
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </StyledCard>
  );
};
