import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Typography, Box, Button, Divider, Alert } from '@mui/material';
import { Check } from '@mui/icons-material';
import { CoachCard } from '../components/CoachCard';
import { MapComponent } from '../components/MapComponent';
import { CampGalleryHome } from '../components/CampGalleryHome';
import { getPresignedUrl } from '../utils/s3Utils';
import { SocialLinks } from '../components/SocialLinks';
import textContent from '../public/en.json';

const StyledHome = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  minHeight: `calc(100vh - 48px)`,
  overflow: 'auto',

  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 48px)`,
  },

  backgroundColor: '#181A1B',
}));

const StyledTypography = styled(Typography)(() => ({
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  margin: '16px auto',
  fontWeight: '100',
  paddingBottom: '8px',
  fontSize: '16px',
}));

const StyledSubHeader = styled(StyledTypography)(({ theme }) => ({
  fontSize: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: `20px`,
  },
  paddingBottom: '0px',
}));

const StyledDivider = styled(Divider)(() => ({
  borderColor: '#CAC4D0',
  width: '80%',
  maxWidth: '800px',
  margin: '48px auto',
}));

const StyledHeader = styled(StyledTypography)(({ theme }) => ({
  fontSize: '45px',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center', // Vertically center the content
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: `36px`,
    flexDirection: 'column',
    lineHeight: '1.5',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: `28px`,
    flexDirection: 'column',
    lineHeight: '1.5',
  },
  display: 'flex',
  flexDirection: 'row',
  gap: 1, // Adds spacing between text and button
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'black',
  fontSize: '14px',
  textTransform: 'none',
  color: 'EEEEEE',
  float: 'right',
  padding: '8px 32px',

  '&:hover': {
    backgroundColor: '#333333',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: '85%',
  maxWidth: '1000px',
  [theme.breakpoints.down('md')]: {
    width: '90%', // Change width to 96% on small screens
  },
  textAlign: 'center',
  margin: '16px auto',
}));

export const Home = () => {
  const address = '801 King Edward Ave, Ottawa, ON K1N 1A2';
  const [jamesUrl, setJamesUrl] = useState('');
  const [chandaUrl, setChandaUrl] = useState('');
  const [antonyUrl, setAntonyUrl] = useState('');
  const [headerUrl, setHeaderUrl] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Fetch thumbnails once component is mounted
  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const antonyKey = { name: 'antony', key: '1080p/antony.webp' };
        const jamesKey = { name: 'james', key: '1080p/james.webp' };
        const chandaKey = { name: 'chanda', key: '1080p/chanda.webp' };
        const headerKey = '1080p/jp7_header.webp';
        const coach = [antonyKey, jamesKey, chandaKey];
        await Promise.all(
          coach.map(async (coach) => {
            const thumbUrl = await getPresignedUrl('jp7images', coach.key);
            if (coach.name === 'james') setJamesUrl(thumbUrl);
            else if (coach.name === 'chanda') setChandaUrl(thumbUrl);
            else if (coach.name === 'antony') setAntonyUrl(thumbUrl);
          })
        );
        const headerUrlVal = await getPresignedUrl('jp7images', headerKey);
        setHeaderUrl(headerUrlVal);
        // eslint-disable-next-line no-restricted-globals
        const params = new URLSearchParams(location.search);
        const isSuccess = params.get('success') === 'true';
        setShowSuccessMessage(isSuccess);
        if (isSuccess) {
          const timer = setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);

          return () => clearTimeout(timer);
        }
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    };

    fetchCoaches();
  }, []);

  return (
    <StyledHome>
      {showSuccessMessage && (
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          Your payment was successful!
        </Alert>
      )}
      <div style={{ width: '100%', textAlign: 'center' }}>
        <StyledBox component="img" src={headerUrl} />
      </div>
      <StyledHeader>
        JP7’s Showcase 2nd Edition
        <Link to="/register">
          <StyledButton variant={'contained'}>Register Now</StyledButton>
        </Link>
      </StyledHeader>
      <StyledSubHeader>{textContent.elevateYourGame}</StyledSubHeader>
      <StyledTypography>{textContent.joinUs}</StyledTypography>
      <StyledTypography>{textContent.showcaseYourSkills}</StyledTypography>
      <StyledTypography>{textContent.campInfo}</StyledTypography>

      <StyledSubHeader>{textContent.campDetails}</StyledSubHeader>
      <StyledTypography>{textContent.footballskillsCampDate}</StyledTypography>
      <StyledTypography>{textContent.prospectShowcaseDate}</StyledTypography>
      <StyledTypography>{textContent.campHours}</StyledTypography>
      <StyledTypography>{textContent.openTo}</StyledTypography>

      <StyledSubHeader>Fees:</StyledSubHeader>
      <StyledTypography>{textContent.youthSkillsCampCost}</StyledTypography>
      <StyledTypography>{textContent.youthFlagShowcaseCost}</StyledTypography>
      <StyledSubHeader>Deadline:</StyledSubHeader>
      <StyledTypography>{textContent.registrationDeadline}</StyledTypography>
      <StyledTypography>{textContent.parents}</StyledTypography>

      <StyledDivider variant="middle" />
      <div style={{ width: '80%', maxWidth: '800px', margin: 'auto' }}>
        <CoachCard
          image={jamesUrl}
          name={textContent.jamesPeter}
          title={textContent.jamesPeterDetails}
          bio={textContent.jamesPeterBio}
        />
      </div>

      <div style={{ width: '80%', maxWidth: '800px', margin: 'auto' }}>
        <CoachCard
          image={antonyUrl}
          name={textContent.antonyKolacek}
          title={textContent.antonyKolacekDetails}
          bio={textContent.antonyKolacekBio}
        />
      </div>

      <div style={{ width: '80%', maxWidth: '800px', margin: 'auto' }}>
        <CoachCard
          image={chandaUrl}
          name={textContent.chandaJones}
          title={textContent.chandaJonesDetails}
          bio={textContent.chandaJonesBio}
        />
      </div>

      <StyledDivider variant="middle" />

      <StyledSubHeader>{textContent.location}</StyledSubHeader>
      <StyledTypography>
        {textContent.superdome}
        <br />
        {textContent.address}
      </StyledTypography>
      <MapComponent address={address} />
      <StyledDivider variant="middle" />
      <CampGalleryHome />
      <StyledDivider variant="middle" />
      <SocialLinks />
    </StyledHome>
  );
};
